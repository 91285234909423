<template>
    <div>
      <TextBox
        :isTitle="true"
        :cefrLevel="payload.cefr_level"
        :label="'Question title'"
        :placeholder="'Insert Question title here'"
        v-model="question_title"
      />
      <Textarea
        :label="'Content text'"
        :rows="10"
        :cefrLevel="payload.cefr_level"
        v-model="long_text"
      />
      <OptionsRadio
        v-model="question"
        :cefrLevel="payload.cefr_level"
        :last="true"
      />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import TextBox from '../../../components/item/TextBox'
import Textarea from '../../../components/item/Textarea'
import OptionsRadio from '../../../components/item/OptionsRadio'
import { v4 as uuidv4 } from 'uuid'

export default {
    data: () => ({
        question_title:'',
        long_text:'',
        question: {
          question: 'Choose the best heading for the text',
          question_id: uuidv4(),
          solution: [''],
          question_options:[
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
            {
                value:'',
                option_id: uuidv4()
            },
          ],
        },
        questions:[],
    }),
    computed: {
        ...mapState('item', ['payload', 'isUpdate']),
    },
    methods:{
        onSubmit() {
            // When creating an item, set the question's solution[id] to be the option_id of the first question_option
            if (!this.isUpdate) this.question.solution[0] = this.question.question_options[0].option_id

            const data = {
                template_type: 'MCQ_GAPFILL_TITLE',
                static_text: "Select the best heading for the text.",
                title: this.question_title,
                gapfill_title: this.long_text,
                questions: [this.question]
            }
            this.$store.commit('item/setPayload', data)
        },
    },
    beforeMount(){
      if (this.isUpdate && Object.keys(this.payload).length) {
        this.question_title = this.payload.title;
        this.long_text = this.payload.long_text;

        // Make sure that the solution is first in the list of question options
        const { question, question_id, question_options, solution  } = this.payload.questions[0];
        const solutionIndex = question_options.findIndex(option => option.option_id === solution[0]);
        const solutionOption = question_options[solutionIndex];
        question_options.splice(solutionIndex, 1);
        question_options.unshift(solutionOption);

        this.question = {
          question,
          question_id,
          question_options,
          solution,
        }
      }
    },
    components:{
      TextBox,
      Textarea,
      OptionsRadio
    }
}

</script>

<style lang="scss" scoped>

.answer
{
  background: rgb(228, 246, 228);
  color: rgb(17, 113, 17);
  border: none;
  border-bottom: 3pt solid rgb(17, 113, 17);
  font-weight: bold;
  padding: 10pt;
  padding-top: 7pt;
  width: unset;
  min-width: 40pt;
  text-align: center;
  font-size: 1em;
  display: inline;
}


.title
{
  font-size: 1.5em;
  font-weight: bold;
  padding: 10pt;
  width: 80%;
  text-align: left;
}


.full-para
{
  flex-wrap: wrap;
  padding: 3pt;
  gap: 10pt;
  max-width: 500pt;
  overflow: hidden;
  word-wrap: normal;
  overflow-wrap: break-word;
}

.full
{
  height: 100pt;
}


.options
{
  display: flex;
  flex-direction: column;
  gap: 10;
}


</style>